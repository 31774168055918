@import '../../../styles/main.scss';

.modalFieldHeader {
  color: $modalTextColor;
}

.requiredField {
  width: $modalRequiredFieldWidth;
  height: $modalRequiredFieldHeight;
  background: $modalRequiredFieldColor;
}

@media (hover: hover) and (pointer: fine) {
  .no-hover:hover {
    cursor: $hoverNotAllowed;
  }
}
