@import '../../../styles/main.scss';

li {
  width: $sideBarItemWidth;
  padding: $sideBarItemPadding;

  a {
    color: $listItemNormalColor;
  }
}

li.selected {
  background-color: $listItemSelectedBackgroundColor;
  border-radius: $sideBarItemSelected;

  a {
    color: $listItemSelectedColor;
  }
}

.sideBarImgContainer {
  width: $sideBarIconContainerWidth;
}
