@import '../../../styles/main.scss';

.offsetDate {
  @include calendarArrows;

  &:first-child {
    @include calendarArrowBorders('right', 'left');
  }

  &:last-child {
    @include calendarArrowBorders('left', 'right');
  }

  &:focus {
    outline: $pagination-focus-outline;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: $calendarHoverColor;
      border-color: $calendarBorderHoverColor;
    }
  }
}
