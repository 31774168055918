@import '../../../styles/main.scss';

.editEntryButton {
  background-color: $actionTableAdd;
  box-shadow: $actionTableAddShadow;
  height: $actionTableAddHeight;
  width: $actionTableEditWidth;
  border-radius: $actionTableAddRadius;
  border: nth($user-selects, index($user-selects, 'none'));
}
