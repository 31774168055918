@import './styles/main.scss';

@font-face {
  font-family: 'SF Pro Text Bold';
  src:
    local('SF Pro Text Bold'),
    url(./styles/SF-Pro-Text-Bold.woff2) format('woff2');
}

@font-face {
  font-family: 'SF Pro Text Medium';
  src:
    local('SF Pro Text Medium'),
    url(./styles/SF-Pro-Text-Medium.woff2) format('woff2');
}

@font-face {
  font-family: 'SF Pro Text Regular';
  src:
    local('SF Pro Text Regular'),
    url(./styles/SF-Pro-Text-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'SF Pro Text Light';
  src:
    local('SF Pro Text Light'),
    url(./styles/SF-Pro-Text-Light.woff2) format('woff2');
}

@font-face {
  font-family: 'SF Pro Display Bold';
  src:
    local('SF Pro Display Bold'),
    url(./styles/SF-Pro-Display-Bold.woff2) format('woff2');
}

@font-face {
  font-family: 'SF Pro Display Medium';
  src:
    local('SF Pro Display Medium'),
    url(./styles/SF-Pro-Display-Medium.woff) format('woff');
}

@font-face {
  font-family: 'SF Pro Display Regular';
  src:
    local('SF Pro Display Regular'),
    url(./styles/SF-Pro-Display-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'SF Pro Display Light';
  src:
    local('SF Pro Display Light'),
    url(./styles/SF-Pro-Display-Light.woff2) format('woff2');
}

@font-face {
  font-family: 'Menlo';
  src:
    local('Menlo'),
    url(./styles/Menlo-Regular.woff2) format('woff2'),
    url(./styles/Menlo-Regular.woff) format('woff');
}

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    'Courier New',
    monospace;
}

input,
select {
  font-family: $SF-Pro-Text-Medium;
  padding-left: #{map-get($spacers, 2)};
}

input:disabled {
  background-color: $modalDisabledColor;
}

tspan {
  font-family: $SF-Pro-Regular;
}

button:disabled {
  background-color: $buttonDisabled;
  pointer-events: none;
}
