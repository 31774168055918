@import '../../../styles/main.scss';

.top-exercises-container {
  width: 100%;
  background-color: $topModulesColor;

  @include media-breakpoint-up(sm) {
    width: 50%;
  }
}

.top-exercises-title {
  color: $topModulesTitleColor;
}

.top-exercises-footer {
  color: $white;
}
