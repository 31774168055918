@import '../../styles/main.scss';

.navBar {
  width: 100%;
  height: $navBarHeight;
  box-shadow: $navBarShadow;
  background: $whiteColor;
  z-index: $zindex-fixed;
}

.navBarSideBarVisible {
  width: calc(100% - #{$sideBarWidth});
  height: $navBarHeight;
  box-shadow: $navBarShadow;
  background: $whiteColor;
  z-index: $zindex-fixed;

  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

.navBarAlert {
  left: 0;
  right: 0;
  margin: auto;
}
