h1 {
  font-size: 36px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 14px;
}

dh-1 {
  font-size: 18px;
}

dh-2 {
  font-size: 14px;
}

t-1 {
  font-size: 28px;
}

t-2 {
  font-size: 24px;
}

t-3 {
  font-size: 18px;
}

s-1 {
  font-size: 14px;
}

b-1,
input,
li,
select {
  font-size: 14px;
}

b-2 {
  font-size: 12px;
}

b-3 {
  font-size: 10px;
}

bt-1 {
  font-size: 18px;
}

bt-2 {
  font-size: 14px;
}

bt-4 {
  font-size: 6px;
}

.sf-display-bold {
  font-family: $SF-Pro-Bold;
}

.sf-display-medium {
  font-family: $SF-Pro-Medium;
}

.sf-display-regular {
  font-family: $SF-Pro-Regular;
}

.sf-display-light {
  font-family: $SF-Pro-Light;
}

.sf-text-bold {
  font-family: $SF-Pro-Text-Bold;
}

.sf-text-medium {
  font-family: $SF-Pro-Text-Medium;
}

.sf-text-regular {
  font-family: $SF-Pro-Text-Regular;
}

.sf-text-light {
  font-family: $SF-Pro-Text-Light;
}
