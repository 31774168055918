@import '../../styles/main.scss';

.addEntryContainer {
  height: $actionTableHeight;
  margin-right: 1em;
}

.addEntryButton {
  background-color: $actionTableAdd;
  box-shadow: $actionTableAddShadow;
  height: $actionTableAddHeight;
  width: $actionTableAddWidth;
  border-radius: $actionTableAddRadius;
  border: nth($user-selects, index($user-selects, 'none'));
}
