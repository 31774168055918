@import '../../../styles/main.scss';

.modalFieldRow {
  > div {
    > input,
    > select,
    .select-field__control {
      height: $modalInputFieldHeight;
      border: $modalInputFieldBorder;
      width: 100%;
    }

    &:first-child {
      margin-right: $spacer;
    }
  }
}

.react-tel-input {
  height: $modalInputFieldHeight;

  > .flag-dropdown {
    border: $modalInputFieldBorder;
  }

  > .form-control {
    border: $modalInputFieldBorder;
  }

  > .fieldStyle {
    height: 100%;
    border: $none;
    border-radius: $loginFlowSubmitBorderRadius;
  }

  > .fieldFlagStyle {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
}
