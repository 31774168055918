@import '../../styles/main.scss';

.settings-container {
  width: $settingsContainerWidth;
}

.fieldContainer-dark {
  background: $settingsDarkBackground;
}

@include media-breakpoint-down(sm) {
  .settings-container {
    width: $settingsContainerResponsiveWidth;
  }
}

button.editAccount {
  height: $settingsEditAccountHeight;
  width: $settingsEditAccountWidth;
}

.settingsEditEntryButton {
  background-color: $actionTableAdd;
  box-shadow: $actionTableAddShadow;
  height: $settingsEditBtnHeight;
  width: $settingsEditBtnWidth;
  border-radius: $actionTableAddRadius;
  border: nth($user-selects, index($user-selects, 'none'));
}
