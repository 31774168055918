@import './styles/main.scss';

@include fade;

.App {
  height: inherit;
}

.mainContent {
  background: $appBackground;
  padding-top: $mainContentNavBarOffset;
}

.mainContentSideBarVisible {
  background: $appBackground;
  padding-top: $mainContentNavBarOffset;
  padding-left: $sideBarWidth;

  @include media-breakpoint-down(md) {
    padding-top: $mainContentNavBarOffset;
    padding-left: 0;
  }
}

.hamburgerForeground {
  &,
  nav {
    z-index: $zindex-modal;
    background: $sidebarColor;
    width: $sideBarWidth;
  }
}

.hamburgerBackground {
  z-index: $zindex-modal-backdrop;
  background: $hamburgerBackgroundColor;
}

.loginFlowBG {
  position: nth($positions, index($positions, 'fixed'));
  top: 0;
  left: 0;
  z-index: -1;
  min-width: map-get($sizes, 100);
  min-height: map-get($sizes, 100);
  background-image: $loginBackground;
}

.loginFormSubmit {
  width: $loginSubmitWidth;
  height: $loginSubmitHeight;
  background: $loginSubmitBackground;
  border-radius: $loginSubmitBorderRadius;
  border: $none;
  color: $white;
}

.accessCodeSubmit {
  // background: linear-gradient(
  //   258deg,
  //   rgb(92, 50, 159),
  //   rgb(12, 78, 254)
  // );
  background-color: black;
  color: white;
  font-size: 20px;
  padding: 10px 60px;
  border-radius: 5px;
  margin: 10px 0;
  cursor: pointer;
}

.accessCodeSubmit :disabled {
  color: grey;
  opacity: 0.7;
  cursor: default;
}

.modal-95vw {
  width: 95vw;    /* Occupy the 95% of the screen width */
  max-width: none !important;
}

.modal-90w {
  width: 90vw;    /* Occupy the 90% of the screen width */
  max-width: 90vw;
}

.jotform-body {
  display: flex;
  justify-content: center;
  max-height: none !important;
  height: 80vh;
}

.jotform-iframe {
  width: 95%;
}

.loginFlowContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: center;
}

.reactCodeInput {
  justify-content: center;
  display: flex;
}
