@import '../../../styles/main.scss';

@include monthlyStatsSection();

.athleteMonthSectionTitle {
  color: $athleteMonthSectionTitleColor;
}

.athleteMonthSectionSubTitle {
  color: $athleteMonthSectionSubTitleColor;
}
