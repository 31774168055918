@import './sizing.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

@mixin fade {
  .fade-enter {
    opacity: 0;
  }

  .fade-enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }
}

@mixin dashboardHeaderDivider {
  width: $headerDividerWidth;
  margin-left: map-get($spacers, 0);
  background-color: $headerDividerColor;
}

@mixin calendarArrows {
  border: map-get($spacers, 0);
  background: $dropdownColor;
  padding: map-get($spacers, 0) $calendarArrowPadding;
  transition: background-color 0.1s, border-color 0.1s;
}

@mixin calendarArrowBorders($borderDirection, $borderRadius) {
  border-#{$borderDirection}: 1px solid $calendarDividerColor;
  border-top-#{$borderRadius}-radius: $calendarArrowBorders;
  border-bottom-#{$borderRadius}-radius: $calendarArrowBorders;
}

@mixin monthlyStatsContainer() {
  .athleteMonthContainer {
    @include media-breakpoint-down(xs) {
      height: auto;
    }

    height: 133px;
  }
}

@mixin athleteTitleSection() {
  .athleteTitleSection {
    @include media-breakpoint-down(xs) {
      width: 100%;
    }

    width: 25%;
  }
}

@mixin monthlyStatsSection() {
  .athleteSections {
    @include media-breakpoint-down(xs) {
      width: 100%;
    }

    width: 70%;
  }
}

@mixin actionTableHeaderEffect {
  background: linear-gradient(180deg, rgb(34, 144, 183), rgb(9, 116, 174));
  border-radius: 13px;
}

@mixin modalStyle() {
  .modal-content {
    border: $modalContentBorder;
    box-shadow: $modalContentBoxShadow;
  }

  .modal-footer {
    background: $modalFooterBackground;
  }

  .modal-open {
    padding-right: 0 !important;
  }
}
