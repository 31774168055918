@import '../../styles/main.scss';

.loginFlowContainer {
  width: $loginFlowContainerWidth;
}

.signUpFlowContainer {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loginFlowSpinner {
  width: $loginFlowFieldWidth;
}

.fieldStyle {
  &::-webkit-input-placeholder {
    color: $modalFieldPlaceholderColor;
  }

  &::-moz-placeholder {
    color: $modalFieldPlaceholderColor;
  }
}

.signUp-row {
  display: flex;
  justify-content: center;
}
