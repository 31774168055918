@import '../../styles/main.scss';

.toggle {
  cursor: pointer;
  width: $toggleWidth;
  height: $toggleHeight;
  border: $toggleBorder;
  border-radius: $toggleRadius;
}

.onBg {
  background: $toggleOnBg;
}

.offBg {
  background: $toggleOffBg;
}

.on {
  height: $toggleButtonHeight;
  border-radius: $toggleButtonRadius;
  padding-right: $toggleButtonPaddingRight;
  margin-left: $toggleOnMarginLeft;
  margin-top: $toggleButtonMarginTop;
  transition: margin-left 0.2s;
  box-shadow: $toggleButtonShadow;
}

.off {
  height: $toggleButtonHeight;
  border-radius: $toggleButtonRadius;
  padding-right: $toggleButtonPaddingRight;
  margin-left: $toggleOffMarginLeft;
  margin-top: $toggleButtonMarginTop;
  transition: margin-left 0.2s;
  box-shadow: $toggleButtonShadow;
}

.text-off {
  margin-left: $toggleOffTextMarginLeft;
}

@include media-breakpoint-down(sm) {
  .on {
    margin-left: $toggleOnMarginLeftResponsive;
  }

  .off {
    margin-left: $toggleOffMarginLeftResponsive;
  }
}
