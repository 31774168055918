@import '../../../styles/main.scss';

.SingleDatePickerInput,
.SingleDatePickerInput > .DateInput_1 {
  width: 100%;
  height: $modalInputFieldHeight;
  border-radius: $loginFlowSubmitBorderRadius;
}

.SingleDatePickerInput > .DateInput {
  > .DateInput_input {
    background: $white;
    border: $modalInputFieldBorder;
    font-family: $SF-Pro-Text-Bold;
    cursor: $hoverPointer;
  }
}

input#dob_login_flow {
  border: 1px solid #4c0a6b;
  border-radius: $loginFlowSubmitBorderRadius;
}

input#dob {
  border-radius: map-get($spacers, 1);
}

.DateInput_input::placeholder {
  color: $modalFieldPlaceholderColor;
}

.SingleDatePickerInput__withBorder {
  border: none;
  border-radius: $loginFlowSubmitBorderRadius;
}
