@import '../../styles/main.scss';

.hamburger {
  width: $hamburgerSize;
  height: $hamburgerSize;
  background-color: $whiteColor;

  .bars {
    background-color: $blackColor;
    width: $hamburgerBarWidth;
    height: $hamburgerBarHeight;
    border: $hamburgerBorder;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      cursor: $hoverPointer;
    }
  }
}
