@import '../../../styles/main.scss';

.changeConfirm,
.changeCancel {
  height: $settingsBtnHeight;
}

@include media-breakpoint-down(sm) {
  .changePasswordForm {
    width: map-get($sizes, 100);
  }
}
