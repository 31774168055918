@import '../../../styles/main.scss';

input[type='tel'] {
  &::-moz-placeholder {
    opacity: revert;
  }

  &:focus {
    &::-moz-placeholder {
      opacity: $mozPhoneOpacity;
    }
  }
}
