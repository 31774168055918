@import '../../../styles/main.scss';

.tableWrapper {
  border: $athleteTableBorder;
  box-shadow: $athleteTableShadow;
}

.athletesTableHeader {
  color: $tableHeaderColor;
}

.athletesTableLink {
  color: $showAllColor;
  line-height: $athleteTableSubTitleLineHeight;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: $showAllHover;
      cursor: pointer;
    }
  }
}

thead > tr > th {
  font-weight: $medium;
}
