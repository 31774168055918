//NavBar
$navBarHeight: 58px;
$navBarShadow: 0 2px 4px rgba(0, 0, 0, 0.05);
$searchBorderRadius: 5px;
$searchHeight: 44px;

//SideBar
$sideBarWidth: 198px;
$sideBarItemWidth: 80%;
$sideBarItemSelected: 4px;
$sideBarItemPadding: 0.15rem 0;
$sideBarIconContainerWidth: 17px;

//Search
$searchIconPositioning: 13px;
$searchIconPosition: absolute;

//Main Content
$mainContentNavBarOffset: #{$navBarHeight + 10px};
$mainContentBreakpoint: 3%;

$hamburgerSize: 24px;
$hamburgerBarWidth: 13px;
$hamburgerBarHeight: 1px;
$hamburgerBorder: 1px solid $blackColor;

//Dashboard
$headerLinkDividers: 0 5px;
$headerDividerWidth: 100%;

//Date Range Picker
$calendarArrowPadding: 10px;
$calendarArrowBorders: 0.25rem;
$calendarIconPadding: 5px;
$calendarIconBottomPadding: 12px;
$calendarTransition: background-color 0.1s, border-color 0.1s;
$calendarWidth: 300px;
$calendarWidthResponsive: 285px;
$calendarButtonHeight: 35px;
$calendarMargin: 100px;
$calendarMarginResponsive: 120px;
$dateRangeSize: 86px;
$dateTextPadding: 3px;

//Athlete Month Stats
$athleteMonthBorderWidth: 0.5px;
$athleteMonthBoxShadow: 0 3px 3px rgba(0, 0, 0, 0.04);
$verticalBarBorderWidth: 2px;
$verticalBarHeight: 82px;
$verticalBarWidth: 3px;

//Top Exercises
$topModulesColumnGap: 20px;
$topModulesTitlePadLeft: 12px;
$topModulesTableRowSpacing: 1px;
$topModulesHorizontalBorder: dotted 0.5px;

//Athlete Table
$athleteTableBorder: 1px solid rgb(221, 228, 235);
$athleteTableShadow: 0 0 15px 4px rgba(90, 113, 208, 0.11);

//Login
$loginFormHeight: 136px;
$loginFormBorderRadius: 20px;
$loginSubmitWidth: 343px;
$loginSubmitHeight: 50px;
$loginSubmitBorderRadius: 16px;
$loginFlowFieldWidth: 330px;
$loginFlowContainerWidth: 350px;
$loginFlowSubmitBorderRadius: 11px;
$loginFlowPassFieldWidth: 276px;
$iconFormWidth: 44px;
$iconFormHeight: 44px;
$iconFormBorderRadius: 16px;
$iconFormBoxShadow: 0 10px 10px rgba(42, 48, 99, 0.1);

//Sign Up
$accessCodeSubmit_background-color: black;
$accessCodeSubmit_color: white;
$accessCodeSubmit_font-size: 20px;
$accessCodeSubmit_padding: 10px 60px;
$accessCodeSubmit_border-radius: 5px;
$accessCodeSubmit_margin: 10px 0;
$accessCodeSubmit_cursor: pointer;

//Action Table
$actionTableHeight: 30px;
$actionTableHeaderDivider: 8px solid $appBackground;
$actionTableWidthBreakpoint: 768px;
$actionTableAddShadow: 0 3px 7px rgba(61, 97, 255, 0.27);
$actionTableAddHeight: 38px;
$actionTableAddWidth: 90.5px;
$actionTableAddRadius: 4px;
$actionTableEditWidth: 70px;

//Modal
$modalContentBorder: 0.5px solid rgba(0, 0, 0, 0.05);
$modalContentBoxShadow: 7px 16px 37px rgba(0, 0, 0, 0.24);
$modalRequiredFieldWidth: 9px;
$modalRequiredFieldHeight: 9px;
$modalInputFieldHeight: 46px;
$modalInputFieldBorder: 1px solid rgb(188, 193, 220);

$modalCancelHeight: 51px;
$modalCancelWidth: 139px;
$modalCancelBoxShadow: 0 2px 7px rgba(0, 0, 0, 0.06);

$modalActionHeight: 51px;
$modalActionWidth: 186px;
$modalActionBoxShadow: 0 3px 7px rgba(61, 97, 255, 0.27);
$mediumModalButtonWidth: 110px;

//Select field
$selectBackgroundSize: 15px;
$selectBackgroundSpacing: 99% 50%;

//Support
$supportContainerWidth: 592px;
$supportContainerHeight: 289px;

//Settings
$settingsEditBtnHeight: 51px;
$settingsEditBtnWidth: 98px;
$settingsBtnHeight: 34px;
$settingsBtnWidth: 70px;
$settingsContainerWidth: 475px;
$settingsContainerResponsiveWidth: 300px;
$settingsEditAccountWidth: 200px;
$settingsEditAccountHeight: 37px;
$settingsAdminInfoWidth: 300px;
$settingsDeleteAccountWidth: 124px;
$settingsDeleteAccountHeight: 37px;
$settingsDeleteAccountRadius: 16px;

//Toggle Switch
$toggleWidth: 77px;
$toggleHeight: 33px;
$toggleBorder: 1px solid rgb(188, 188, 188);
$toggleRadius: 30px;

$toggleButtonHeight: 27px;
$toggleButtonRadius: 30px;
$toggleButtonMarginTop: 2px;
$toggleButtonPaddingRight: 27px;
$toggleButtonShadow: 0 2px 4px rgb(144, 143, 143);
$toggleOnMarginLeft: 4px;
$toggleOnMarginLeftResponsive: 0;
$toggleOffMarginLeft: 45px;
$toggleOffMarginLeftResponsive: 22px;
$toggleOffTextMarginLeft: 40px;
