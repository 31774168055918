@import '../../../styles/main.scss';

.custom-tooltip {
  margin: 0;
  padding: 10px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
}

.AthletesGraphContainer {
  width: 100%;

  @include media-breakpoint-up(sm) {
    width: 50%;
  }
}
