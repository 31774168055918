@import './fonts.scss';
@import './colors.scss';
@import './sizing.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.DateInput_input,
.DateRangePickerInput {
  background: $dropdownColor;
  color: $fontPrimaryColor;
  font-family: $SF-Pro-Regular;
  font-size: $dashboardSubsection;

  #sdate {
    text-align: right;
    padding-right: $dateTextPadding;
  }

  #edate {
    text-align: left;
    padding-left: $dateTextPadding;
  }
}

.DateRangePickerInput_calendarIcon {
  margin: map-get($spacers, 0);
  padding: $calendarIconBottomPadding $calendarIconPadding;
  transition: $calendarTransition;

  &:focus {
    outline: $pagination-focus-outline;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: $calendarHoverColor;
      border-color: $calendarBorderHoverColor;
    }
  }
}

.DateInput__small {
  width: $dateRangeSize;
}
