@import '../../styles/main.scss';

.dashboard-title {
  color: $fontPrimaryColor;
}

hr {
  @include dashboardHeaderDivider;
}

.arrows {
  color: $arrowColor;
}
