@import '../../styles/main.scss';

.supportPage {
  background: $supportContainerBackground;
  width: $supportContainerWidth;
  height: $supportContainerHeight;
}

.clipboardLink {
  color: $loginFlowLink;
  cursor: pointer;
}
