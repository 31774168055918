@import '../../../styles/main.scss';

@include monthlyStatsContainer();

.athleteMonthContainer {
  background: $whiteColor;
  border-color: $athleteMonthBorderColor;
  border-width: $athleteMonthBorderWidth;
  box-shadow: $athleteMonthBoxShadow;
}

.vertical {
  background: $verticalBarBackground;
  border-color: $verticalBarBorderColor;
  border-width: $verticalBarBorderWidth;
  height: $verticalBarHeight;
  width: $verticalBarWidth;
}
