$SF-Pro-Text-Bold: 'SF Pro Text Bold';
$SF-Pro-Text-Medium: 'SF Pro Text Medium';
$SF-Pro-Text-Regular: 'SF Pro Text Regular';
$SF-Pro-Text-Light: 'SF Pro Text Light';
$SF-Pro-Bold: 'SF Pro Display Bold';
$SF-Pro-Regular: 'SF Pro Display Regular';
$SF-Pro-Medium: 'SF Pro Display Medium';
$SF-Pro-Light: "SF Pro Display Light";

//common
$bold: 700;
$medium: 400;
$light: 300;

//Dashboard
//NavBar
$searchPlaceholderFont: 13px;

//SideBar
$sideItemFont: 14px;
$iconFont: 8px;
$clubNameFont: 13px;
$clubInfoGreeting: 14px;
$clubInfoExerciseCount: 18px;
$clubInfoActives: 12px;
$clubHeadingRole: 8px;
$creditsTitle: 12px;
$creditsVersioning: 6px;

//Heading
$dashboardTitle: 20px;
$dashboardSubsection: 14px;

//Athlete Month Stats
$athleteMonthTitle: 18px;
$athleteMonthSubTitle: 14px;
$athleteMonthSectionTitle: 28px;
$athleteMonthSectionSubTitle: 13px;

//Top Exercises
$topModulesTitle: 18px;
$topModulesFooter: 12px;

//Athletes Table
$athleteTableTitle: 15px;
$athleteTableSubTitle: 13px;
$athleteTableSubTitleLineHeight: 14px;
$athleteTableShowAll: 12px;
$athleteTableEntry: 14px;

//Login
$appTitleFontSize: 36px;
$loginFieldFontSize: 14px;
$loginSubmitFontSize: 14px;
$loginLinkFontSize: 15px;

//Modal
$modalInputFontSize: 14px;
