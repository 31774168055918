@import '../../../styles/main.scss';

.passField {
  width: $loginFlowPassFieldWidth;
  height: $iconFormHeight;
  border-top-left-radius: $loginFlowSubmitBorderRadius;
  border-bottom-left-radius: $loginFlowSubmitBorderRadius;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.passField:focus {
  outline: none !important;
  border: 1px solid red;
  box-shadow: 0 0 15px #1e3098;
}

.passwordDisplayIcon {
  background: $white;
  border-top-right-radius: $loginFlowSubmitBorderRadius;
  border-bottom-right-radius: $loginFlowSubmitBorderRadius;
}

.passContainer {
  border-radius: $loginFlowSubmitBorderRadius;
  border: 1px solid #4c0a6b;
}
