@import '../../styles/main.scss';

.loginAlert {
  position: nth($positions, index($positions, 'absolute'));
  top: map-get($sizes, 25);
  left: map-get($sizes, 50);
  transform: translate(-50%, -50%);
}

.inputFormLogin {
  width: $loginFlowContainerWidth;
  height: $loginFormHeight;
  border-radius: $loginFormBorderRadius;
  background-color: $white;
}

.loginLinkContainer {
  width: $loginFlowContainerWidth;
}

.iconContainer {
  width: $iconFormWidth;
  height: $iconFormHeight;
  border-radius: $iconFormBorderRadius;
  background: $white;
  box-shadow: $iconFormBoxShadow;
}

input.loginFieldInput[type='email'] {
  &::-webkit-input-placeholder {
    color: $loginFieldColor;
  }

  &::-moz-placeholder {
    color: $loginFieldColor;
    opacity: 1;
  }

  &:focus {
    outline: none;
  }
}

input.loginFieldInput[type='password'] {
  &::-webkit-input-placeholder {
    color: $loginFieldColor;
  }

  &::-moz-placeholder {
    color: $loginFieldColor;
    opacity: 1;
  }

  &:focus {
    outline: none;
  }
}
