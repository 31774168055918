@import '../../../styles/main.scss';

.formContainer > .fieldStyle {
  width: map-get($sizes, 50);
}

.settingsConfirm,
.settingsCancel {
  width: $settingsBtnWidth;
  height: $settingsBtnHeight;
}

@include media-breakpoint-down(sm) {
  .settingsConfirm,
  .settingsCancel {
    width: map-get($sizes, 50);
  }

  .formContainer > .fieldStyle {
    width: map-get($sizes, 100);
  }
}
