//common colors
$blackColor: black;
$whiteColor: white;
$fontPrimaryColor: rgb(0, 23, 55);
$fontSecondaryColor: rgb(33, 37, 41);
$appBackground: rgb(247, 247, 247);
$buttonDisabled: rgba(169, 169, 169, 0.81);

//action table
$actionTableAdd: rgb(61, 97, 255);
$actionTableColor: rgba(0, 0, 0, 0.5);
$actionTableActionsColor: rgba(0, 0, 0, 0.65);

//page specific

//Dashboard
//Navbar
$searchColor: linear-gradient(to right, rgb(2, 109, 172), rgb(39, 150, 185));
$searchBorderColor: rgba(0, 0, 0, 0.05);

//Sidebar
$sidebarColor: linear-gradient(to bottom, rgb(0, 107, 171), rgb(42, 153, 186));
$listItemNormalColor: rgba(255, 255, 255, 0.7);
$listItemIconNormalColor: rgba(255, 255, 255, 0.5);
$listItemSelectedColor: rgb(255, 255, 255);
$listItemSelectedBackgroundColor: rgb(36, 56, 171);
$clubInfoColor: rgba(255, 255, 255, 0.8);
$hamburgerBackgroundColor: rgba(0, 0, 0, 0.3);

//Header
$dropdownColor: rgb(221, 228, 235);
$arrowColor: rgb(43, 128, 255);
$headerDividerColor: rgba(205, 214, 220, 0.5);

//Training Hours
$trainingHourBarsColor: rgb(130, 106, 249);

//Top Exercises
$topModulesColor: rgb(3, 10, 172);
$topModulesTitleColor: rgb(201, 201, 201);
$topModulesHorizontalColor: rgba(151, 151, 151, 0.25);

//Athlete Table
$tableRowColor: rgb(237, 242, 247);
$tableHeaderColor: rgb(167, 175, 183);
$showAllHover: rgba(0, 0, 0, 0.25);
$showAllColor: rgb(20, 88, 232);

//Date Range Picker
$calendarHoverColor: #5a6268;
$calendarBorderHoverColor: #545b62;
$calendarDividerColor: rgb(196, 208, 220);

//Athlete Month Stats
$athleteMonthBorderColor: rgba(0, 0, 0, 0.4);
$verticalBarBackground: rgba(0, 0, 0, 0.05);
$verticalBarBorderColor: rgba(0, 0, 0, 0.14);
$athleteMonthTitleColor: rgba(0, 0, 0, 0.65);
$athleteMonthSubTitleColor: rgba(0, 0, 0, 0.62);
$athleteMonthSectionTitleColor: rgba(0, 0, 0, 0.5);
$athleteMonthSectionSubTitleColor: rgba(0, 0, 0, 0.5);

//Login
$loginBackground: linear-gradient(191deg, rgb(1, 242, 176), rgb(6, 155, 217));
$loginSubmitBackground: linear-gradient(
  258deg,
  rgb(92, 50, 159),
  rgb(12, 78, 254)
);
$loginFieldColor: rgb(92, 116, 159);

//Modal
$modalTextColor: rgba(0, 0, 0, 0.7);
$modalRequiredFieldColor: rgb(255, 88, 103);
$modalCancelBackground: rgba(0, 0, 0, 0.33);
$modalSaveBackground: rgb(61, 97, 255);
$modalConfirmBackground: rgb(236, 69, 77);
$modalFooterBackground: rgba(191, 194, 215, 0.38);
$modalFieldPlaceholderColor: rgba(0, 0, 0, 0.33);
$modalDisabledColor: rgba(239, 239, 239, 0.3);
$modalInputDisabledColor: rgb(250, 250, 250);

//Login Flow
$loginFlowLink: rgb(11, 79, 253);
$fieldError: rgb(178, 19, 18);

//Support
$supportContainerBackground: rgba(167, 182, 220, 0.2);

//Settings
$settingsDarkBackground: rgb(73, 73, 73);

//Toggle Switch
$toggleOnBg: rgb(188, 188, 188);
$toggleOffBg: rgb(21, 130, 179);
