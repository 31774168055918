@import '../../styles/main.scss';

.modal-title {
  display: nth($displays, index($displays, 'flex'));
  align-items: center;
  line-height: 1.3;
  color: $modalTextColor;
}

.modal-body {
  color: $modalTextColor;
}

.mediumCancel-btn {
  width: $mediumModalButtonWidth;
}

.mediumConfirm-btn {
  width: $mediumModalButtonWidth;
}

.largeCancel-btn {
  width: $modalCancelWidth;
}

.largeConfirm-btn {
  width: $modalActionWidth;
}

@include modalStyle();

@include media-breakpoint-up(xs) {
  .modalCancel {
    height: $modalCancelHeight;
    background: $modalCancelBackground;
    box-shadow: $modalCancelBoxShadow;
  }

  .modalSave {
    height: $modalActionHeight;
    background: $modalSaveBackground;
    box-shadow: $modalActionBoxShadow;
  }

  .modalConfirm {
    height: $modalActionHeight;
    background: $modalConfirmBackground;
    box-shadow: $modalActionBoxShadow;
  }
}

@include media-breakpoint-down(xs) {
  .footer-actions {
    width: map-get($sizes, 100);
  }

  .modalCancel {
    width: map-get($sizes, 100);
    margin-bottom: $spacer;
  }

  .modalSave {
    width: map-get($sizes, 100);
  }

  .modalConfirm {
    width: map-get($sizes, 100);
  }
}

.modal-field {
  &::-webkit-input-placeholder {
    color: $modalFieldPlaceholderColor;
  }

  &::-moz-placeholder {
    color: $modalFieldPlaceholderColor;
  }

  height: $modalInputFieldHeight;
  border: $modalInputFieldBorder;
  width: 100%;
  border-radius: $loginFlowSubmitBorderRadius;
}
