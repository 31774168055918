@import '../../../styles/main.scss';

@include athleteTitleSection();

.athleteMonthTitle {
  color: $athleteMonthTitleColor;
}

.athleteMonthSubTitle {
  color: $athleteMonthSubTitleColor;
}
