@import '../../../styles/main.scss';

.field {
  width: $loginFlowFieldWidth;
}

div.fieldContainer {
  > div {
    margin-bottom: map-get($spacers, 0);

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fieldStyle {
  height: $iconFormHeight;
  border-radius: $loginFlowSubmitBorderRadius;
  border: 1px solid #4c0a6b;
}

.fieldStyle:focus {
  outline: none !important;
  box-shadow: 0 0 15px #1e3098;
}

.fieldText {
  font-family: 'SF Pro Text Bold';
  color: #4c0a6b;
}

.fieldError {
  color: $fieldError;
}
