@import '../../../../styles/main.scss';

.dashboard-header-link {
  cursor: not-allowed;

  &:not(:last-child)::after {
    content: '|';
    margin: $headerLinkDividers;
  }

  a.main-nav {
    pointer-events: $none;
    text-decoration: $none;
  }
}
