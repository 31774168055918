@import '../../../styles/main.scss';

.react-calendar {
  z-index: $zindex-modal;
  margin-top: $calendarMargin;
  width: $calendarWidth;

  @include media-breakpoint-down(xs) {
    margin-top: $calendarMarginResponsive;
    width: $calendarWidthResponsive;
  }
}

.DateRangePickerInput {
  height: $calendarButtonHeight;
}
