@import '../../../styles/main.scss';

div {
  > .select-field__control {
    font-family: $SF-Pro-Text-Bold;
    height: 45px;
    border-radius: map-get($spacers, 1);
  }

  > .select-field-login-flow__control {
    font-family: $SF-Pro-Text-Bold;
    height: 45px;
    border: $none;
    border-radius: 11px;
  }
}

.select-field-login-flow__value-container > .select-field-login-flow__placeholder,
.select-field__value-container > .select-field__placeholder {
  color: $modalFieldPlaceholderColor;
  font-size: $modalInputFontSize;
}

.select-field__menu {
  .select-field__menu-list {
    > .select-field__option {
      font-family: $SF-Pro-Text-Bold;
      font-size: $modalInputFontSize;
    }
  }
}

.select-field__single-value {
  font-size: $modalInputFontSize;
}
